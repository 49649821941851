<template>
  <div>
    <!-- promotion-price -->
    <div
      v-if="list.length > 0"
      class="promotion-price discount-li"
    >
      <div class="left-label">
        <span>{{ label }}</span>
        <count-down-time
          v-if="promotionCountDown >= 0"
          :time-stamp="promotionCountDown"
          :notReload="true"
          :notHide="true"
          class="discount-countdown"
          font-size="10px"
        />
      </div>
      <div class="right-price">
        {{ rightPrice.amountWithSymbol }}
        <span
          class="discount-icon"
          @click="handleFold"
        >
          <Icon
            v-if="isPromotionFold"
            key="promotion-down"
            name="sui_icon_more_down_16px"
          />
          <Icon
            v-else
            key="promotion-up"
            name="sui_icon_more_up_16px"
          />
        </span>
      </div>
    </div>
    <!-- promotionList -->
    <div
      v-if="list.length > 0"
      v-show="!isPromotionFold"
      class="promotion-list"
    >
      <li
        v-for="item in list"
        v-show="item.discount_price.amount > 0 || item.typeId == 29"
        :key="item.id"
      >
        <div class="promotion-item-imgs">
          <MiniImgInfo
            v-for="id in (item.ids || [])"
            :key="id+item.id"
            :item="cartItemMap[id] || {}"
            :options="{
              width: 32,
              height: 32,
              showCheckbox: false,
              showStock: false
            }"
            class="item-img"
          />
        </div>
        <div class="promotion-item-content">
          <span v-html="item.tip"></span>
          <i
            v-if="item.subTip"
            class="suiiconfont sui_icon_doubt_16px_2"
            @click.stop="() => handleDialogShow(item.subTip)"
          >
          </i>
          | 
          {{ template('', language.SHEIN_KEY_PWA_23921) }}
          {{ item.discount_price.amountWithSymbol }}
        </div>
      </li>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from '@/public/src/services/eventCenter'
import MiniImgInfo from './miniImgInfo.vue'

export default {
  components: {
    MiniImgInfo,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    isSuggested: {
      type: [Boolean, String],
      default: '',
    },
    rightPrice: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
    promotionCountDown: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isPromotionFold: true,
    }
  },
  computed: {
    ...mapState('cart', ['language', 'cartInfo', 'cartItemMap']),
    isSuggestedSpecialDe() {
      return this.isSuggested === 'special_de'
    },
    label() {
      switch (this.type) {
        case 'promotionA':
          return `${this.isSuggestedSpecialDe ? this.language.SHEIN_KEY_PWA_31462 : this.language.SHEIN_KEY_PWA_30770}:`
        case 'promotionB':
          return `${this.isSuggestedSpecialDe ? this.language.SHEIN_KEY_PWA_31463 : this.language.SHEIN_KEY_PWA_30772}:`
        default:
          return `${this.language.SHEIN_KEY_PWA_29753}:`
      }
    },
  },
  methods: {
    template,
    handleFold() {
      this.isPromotionFold = !this.isPromotionFold
      daEventCenter.triggerNotice({
        daId: '1-8-7-64',
        extraData: {
          is_open: Number(!this.isPromotionFold),
          type: 'promotion',
        }
      })
    },
    handleDialogShow(tip) {
      this.$emit('click-tip', tip)
    },
  },
}
</script>

<style lang="less" scoped>
.promotion-list {
  background: #f6f6f6;
  li {
    padding: 16/75rem 24/75rem;
  }
  .promotion-item-imgs {
    display: flex;
    flex-wrap: wrap;
    gap: 14/75rem; // for place 9 imgs gap 7px, confirmed with UED
  }
  .promotion-item-content {
    margin-top: 16/75rem;
    font-size: 12px;
    color: @sui_color_gray_dark3;
  }
  .promotion-sub-tip {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.30);
  }
}
</style>
