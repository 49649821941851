<template>
  <div
    class="mini-img-container"
    :style="{
      width: `${config.width/37.5}rem`,
      height: `${config.height/37.5}rem`
    }"
    @click="handleImgInfoClick"
  >
    <TransformImg
      :img-src="getMainImageUrl(item, IS_RW)"
      :is-first-page="false"
      :use-origin-img="true"
      :use-picture="false"
      :alt="item.product.goods_name"
      fetchpriority="high"
    />
    <div
      v-if="config.showCheckbox"
      :class="[
        'img-checkbox',
        {'img-checkbox-disable': item.isDisabled || soldoutIds.includes(item.id)}
      ]"
    >
      <SCheckbox
        :label="item.id"
        :disabled="item.isDisabled || soldoutIds.includes(item.id)"
        :value="checkedStatus"
        :margin-r="20 / 75 + 'rem'"
      />
    </div>
    <template
      v-if="config.showStock"
    >
      <ImgStockBar
        :item="item"
        :config="{
          showAddon: true
        }"
        :ctx="{
          language,
          soldoutIds,
          locals,
          flashSaleType,
          flashSaleItemNum,
          cartDiscountLogoA,
        }"
      />
    </template>
  </div>
</template>
<script>
import TransformImg from 'public/src/pages/components/transformImg'
import { getMainImageUrl } from 'public/src/pages/cartNew/utils/index.js'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { template } from '@shein/common-function'
import { daEventCenter } from '../../../../services/eventCenter'
import ImgStockBar from '../list/item/ImgStockBar.vue'

const { IS_RW } = gbCommonInfo

export default {
  name: 'MiniImgInfo',
  components: {
    TransformImg,
    ImgStockBar,
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    options: { // 传入配置, 将覆盖内部默认配置
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      IS_RW,
      defaultConfig: {
        width: 64, // 设计稿中的宽度px, 组件会自定转换为rem
        height: 64,
        showCheckbox: true,
        showStock: true
      }
    }
  },
  computed: {
    ...mapState('cart', ['isCheckedByLocal', 'language', 'soldoutIds', 'locals']),
    ...mapGetters('cart', ['cartList', 'cartDiscountLogoA']),
    config() {
      return { ...this.defaultConfig, ...this.options }
    },
    checkedStatus() {
      return typeof this.isCheckedByLocal[this.item.id] === 'number' ? Boolean(this.isCheckedByLocal[this.item.id]) : Boolean(this.item.is_checked)
    },
    // 转换闪购类型, 0: 非闪购, 1: 普通闪购, 2: 新人闪购, 3: 特殊闪购, 4: 分人群专区闪购, 5: 不分人群专区闪购, -1: 直播闪购
    flashSaleType() {
      return this.item.promotionTypeIds.includes(10) ? this.item.flashType : (this.item.promotionTypeIds.includes(11) ? -1 : 0)
    },
    flashSaleItemNum() {
      return this.getFlashSaleItemNum()
    }
  },
  methods: {
    ...mapActions('cart', ['fetchModifyCartCheckStatus']),
    ...mapMutations('cart', ['updateState']),
    getMainImageUrl,
    template,
    getFlashSaleItemNum() {
      const item = this.item
      // wiki.pageId=843279127
      const promotionFlash = item.product.product_promotion_info.filter(i => [10, 11].includes(i.type_id))
      const { stock = 0, sold_num = 0 } = promotionFlash[0] || {}
      const realTimeInventory = +item.realTimeInventory || 0
      const flashNum = stock - sold_num
      const minNum = Math.min(realTimeInventory, flashNum)
      const maxNum = Math.max(realTimeInventory, flashNum)
      return minNum > 0 ? minNum : maxNum
    },
    handleImgInfoClick() {
      if(!this.config.showCheckbox || this.item.isDisabled || this.soldoutIds.includes(this.item.id)) return
      else {
        this.handleCheckedItem({ value: this.item.id })
      }
    },
    handleCheckedItem ( { value = '' }){
      let targetItem = this.cartList.find(item => item.id == value)
      // 优先使用存在本地缓存的选中状态
      const checkedStatus = typeof this.isCheckedByLocal[value] === 'number' ? this.isCheckedByLocal[value] : targetItem.is_checked
      if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
        this.$toast(this.language.SHEIN_KEY_PWA_20624)
        return
      }
      let reqParam = {
        operation_type: checkedStatus ? 2 : 1,
        cart_id_list: [],
        editCartId: value
      }
      reqParam.cart_id_list.push(value)
      if(!checkedStatus){
        reqParam.cart_id_list = reqParam.cart_id_list.concat(targetItem.appendIds || [])
      }

      daEventCenter.triggerNotice({
        daId: '1-8-3-7',
        extraData: {
          select_type: 1,
          is_select: checkedStatus,
          goods_sn: targetItem.product.goods_sn,
          position: 'popup',
        }
      })
      this.fetchModifyCartCheckStatus(reqParam)
    },
  }
}
</script>
<style lang="less" scoped>
.mini-img-container {
  position: relative;
  overflow: hidden;
  align-items: center;

  .flexbox();
  img {
    width: 100%;
    display: block;
  }
}
.img-checkbox {
  position: absolute;
  top: 4/75rem;
  right: 4/75rem;
  z-index: 2;
  /deep/ label {
    margin-right: 0!important;/* stylelint-disable-line declaration-no-important */
  }
}
.img-checkbox-disable { opacity: 0.6; }
::v-deep .cart-item__insufficientStock {
  font-size: 10px;
}
</style>
