var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mini-img-container",style:({
    width: `${_vm.config.width/37.5}rem`,
    height: `${_vm.config.height/37.5}rem`
  }),on:{"click":_vm.handleImgInfoClick}},[_c('TransformImg',{attrs:{"img-src":_vm.getMainImageUrl(_vm.item, _vm.IS_RW),"is-first-page":false,"use-origin-img":true,"use-picture":false,"alt":_vm.item.product.goods_name,"fetchpriority":"high"}}),_vm._v(" "),(_vm.config.showCheckbox)?_c('div',{class:[
      'img-checkbox',
      {'img-checkbox-disable': _vm.item.isDisabled || _vm.soldoutIds.includes(_vm.item.id)}
    ]},[_c('SCheckbox',{attrs:{"label":_vm.item.id,"disabled":_vm.item.isDisabled || _vm.soldoutIds.includes(_vm.item.id),"value":_vm.checkedStatus,"margin-r":20 / 75 + 'rem'}})],1):_vm._e(),_vm._v(" "),(_vm.config.showStock)?[_c('ImgStockBar',{attrs:{"item":_vm.item,"config":{
        showAddon: true
      },"ctx":{
        language: _vm.language,
        soldoutIds: _vm.soldoutIds,
        locals: _vm.locals,
        flashSaleType: _vm.flashSaleType,
        flashSaleItemNum: _vm.flashSaleItemNum,
        cartDiscountLogoA: _vm.cartDiscountLogoA,
      }}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }